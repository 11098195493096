import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import Selectors from '../Selectors';
import ActionCreator from '../ActionCreator';
import CVSSelector from '../Components/CVSSelector';
import * as Widget from '../Components/Widget';

class CheckoutForm extends React.Component {
  constructor(props) {
    super(props);
    let { profile, cartData } = this.props;
    const defaultValues = {
      receiver: cartData.receiver || profile.name,
      email: cartData.email || profile.email,
      address: cartData.address || profile.address,
      phone: cartData.phone || profile.phone,
      payment_type: cartData.payment_type || 'ecpay',
      store_id: cartData.store_id,
      store_name: cartData.store_name,
    };

    this.state = {
      values: defaultValues,
      submitting: false,
    };
  }

  render() {
    let { extraCss } = this.props;
    let { values, submitting } = this.state;

    return (
      <CheckoutForm.Wrapper extraCss={extraCss}>
        <h2
          style={{
            textAlign: 'center',
            marginBottom: 20,
            paddingBottom: 5,
            borderBottom: '1px solid grey',
          }}
        >
          結帳資料
        </h2>
        <h3 style={{ marginBottom: 10 }}>收件人</h3>
        <CheckoutForm.Field>
          <label>姓名</label>
          <input
            value={values.receiver}
            onChange={this._onInputUpdate('receiver')}
          />
        </CheckoutForm.Field>

        <CheckoutForm.Field>
          <label>聯絡電話</label>
          <input value={values.phone} onChange={this._onInputUpdate('phone')} />
        </CheckoutForm.Field>

        <CheckoutForm.Field>
          <label>Email</label>
          <input value={values.email} onChange={this._onInputUpdate('email')} />
        </CheckoutForm.Field>

        <h3 style={{ marginBottom: 10 }}>付款</h3>
        <CheckoutForm.Field>
          <label>方式</label>
          <select
            value={values.payment_type}
            onChange={this._onInputUpdate('payment_type')}
          >
            <option value="ecpay">線上刷卡</option>
            <option value="ecpay_cvs">超商貨到付款</option>
            {/*<option value='paypal'>Paypal</option>*/}
            {/*<option value='offline'>匯款</option>*/}
          </select>
        </CheckoutForm.Field>

        {values.payment_type === 'ecpay_cvs' ? (
          <div style={{ marginBottom: 10 }}>
            <CVSSelector
              store_id={values.store_id}
              store_name={values.store_name}
              onSelect={this._onCVSSelect}
            />
          </div>
        ) : (
          <div>
            <h3 style={{ marginBottom: 10 }}>寄送地址</h3>
            <div style={{ height: 10 }} />
            <CheckoutForm.Field>
              <textarea
                value={values.address}
                onChange={this._onInputUpdate('address')}
              />
            </CheckoutForm.Field>
          </div>
        )}

        {submitting ? (
          <Widget.Center>
            <Widget.Spinner />
          </Widget.Center>
        ) : (
          <CheckoutForm.PayNow
            onClick={this._checkout}
            disabled={!this._isAllowNextStep()}
          >
            下一步
          </CheckoutForm.PayNow>
        )}
      </CheckoutForm.Wrapper>
    );
  }

  _isAllowNextStep = () => {
    let { values } = this.state;
    if (
      !values.receiver ||
      !values.email ||
      !values.phone ||
      !values.payment_type
    ) {
      return false;
    }

    if (values.payment_type === 'ecpay_cvs') {
      return values.store_id && values.store_name;
    }

    return values.address;
  };

  _onInputUpdate = key => e => {
    let { values } = this.state;
    this.setState({
      values: {
        ...values,
        [key]: e.target.value,
      },
    });
  };

  _checkout = () => {
    let { values } = this.state;
    let { appActions, navActions } = this.props;
    this.setState({ submitting: true });
    appActions.cart.checkout(values).then(order => {
      this.setState({ submitting: false });
      navActions.push(`/order?id=${order.id}`);
    });
  };

  _onCVSSelect = form => {
    let { values } = this.state;
    let { appActions } = this.props;
    appActions.cart.setData(values, false).then(() => {
      // window.location = `/cvs?store_id=${Math.floor(Math.random()*100)}&store_name=STORE_${Math.floor(Math.random()*100)}`
      form.submit();
    });
  };

  static Wrapper = styled.div`
    max-width: 300px;
    margin: 0 auto;
    ${props => props.extraCss || ''};
  `;

  static Field = styled.div`
    margin-bottom: 10px;

    & > label {
      display: inline-block;
      margin-right: 10px;
      width: 80px;
    }

    & > input {
      border: none;
      border-bottom: 1px solid #ccc;
      text-align: right;
      width: 200px;
    }

    & > input:focus {
      outline: none;
    }

    & > select {
      width: 200px;
      text-align-last: right;
    }

    & > select > option {
      direction: rtl;
    }

    & > textarea {
      width: 300px;
      height: 100px;
    }
  `;

  static PayNow = styled.button`
    display: block;
    margin: 0 auto;
    padding: 8px 12px;
    border-radius: 28px;
    font-size: 20px;
    min-width: 200px;
    ${props => (props.disabled ? 'color: #ccc;' : '')};
  `;
}

export default connect(
  (state, ownProps) => ({
    profile: Selectors.getLoginUser(state),
    cartData: Selectors.cart.getData(state),
  }),
  ActionCreator
)(CheckoutForm);
