import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import ActionCreator from '../ActionCreator';
import Selectors from '../Selectors';
import Link from './Link';
import * as Widget from '../Components/Widget';

class ItemInCart extends React.Component {
  constructor(props) {
    super(props);
    const defaultValues = {
      quantity: '1',
      speed: '2M',
      days: '5',
    };

    let { config } = this.props;
    this.state = {
      values: this._mergeValuesAndConfig(defaultValues, config),
      price: (config && config.price) || null,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.config !== this.props.config) {
      this._updateInternalValuesFromConfig(nextProps.config);
    }
  }

  render() {
    let { product, extraCss, display = 'product' } = this.props;
    let { values, price } = this.state;

    let WrapperComp = ItemInCart.Wrapper;
    if (display === 'cart') {
      WrapperComp = ItemInCart.RowWrapper;
    }

    return (
      <WrapperComp extraCss={extraCss}>
        {display === 'cart' && <h3>{product.name}</h3>}

        <ItemInCart.Field>
          <label>速度</label>
          <select value={values.speed} onChange={this._onInputUpdate('speed')}>
            <option value="2M">2M</option>
            <option value="7M">7M</option>
            <option value="21M">21M</option>
          </select>
        </ItemInCart.Field>

        <ItemInCart.Field>
          <label>天數</label>
          <input
            value={values.days}
            type="number"
            onChange={this._onInputUpdate('days')}
          />
        </ItemInCart.Field>

        <ItemInCart.Field>
          <label>數量</label>
          <input
            value={values.quantity}
            type="number"
            onChange={this._onInputUpdate('quantity')}
          />
        </ItemInCart.Field>

        {price !== null && (
          <ItemInCart.PriceBar>
            <h3>{`小記 $${price}`}</h3>
          </ItemInCart.PriceBar>
        )}

        {this._renderActionBar()}
      </WrapperComp>
    );
  }

  _renderActionBar = () => {
    let { display, profile } = this.props;
    let { price, showSpinner } = this.state;

    if (showSpinner) {
      return (
        <ItemInCart.ActionButtonBar>
          <Widget.Spinner />
        </ItemInCart.ActionButtonBar>
      );
    }

    if (display === 'cart') {
      let { config } = this.props;
      if (profile) {
        if (price !== config.price) {
          return (
            <ItemInCart.ActionButtonBar>
              <button onClick={this._abandonChanges}>放棄修改</button>

              <button onClick={this._modifyCart('set')}>更新購物車</button>

              <button onClick={this._modifyCart('del')}>移除</button>
            </ItemInCart.ActionButtonBar>
          );
        }
        return (
          <ItemInCart.ActionButtonBar>
            <button onClick={this._modifyCart('del')}>移除</button>
          </ItemInCart.ActionButtonBar>
        );
      }
      return null;
    }

    // produce detail view
    let { addToCartResult } = this.state;
    if (addToCartResult) {
      return (
        <ItemInCart.ActionButtonBar>
          <h3>成功加入購物車!</h3>

          <button onClick={() => this.setState({ addToCartResult: null })}>
            OK
          </button>

          <button>
            <Link
              to="/cart"
              extraStyle={{ color: 'white', textDecoration: 'none' }}
            >
              <div>前往購物車</div>
            </Link>
          </button>
        </ItemInCart.ActionButtonBar>
      );
    } else {
      return (
        <ItemInCart.ActionButtonBar>
          {price === null ? (
            <button onClick={this._calcPrice}>計算價錢</button>
          ) : (
            <button onClick={this._modifyCart('add')}>加入購物車</button>
          )}
        </ItemInCart.ActionButtonBar>
      );
    }
  };

  _abandonChanges = () => {
    let { config, onConfigInvalidated } = this.props;
    this._showSpinner(660);
    this._updateInternalValuesFromConfig(
      config,
      () => onConfigInvalidated && onConfigInvalidated(false)
    );
  };

  _updateInternalValuesFromConfig = (config, callback) => {
    let { values, price } = this.state;
    this.setState(
      {
        values: this._mergeValuesAndConfig(values, config),
        price: (config && config.price) || price,
      },
      () => {
        if (callback) {
          callback();
        }
      }
    );
  };

  _mergeValuesAndConfig = (values, config) => {
    if (!config) {
      return values;
    }

    let nextValue = {};
    for (let key in values) {
      nextValue[key] = values[key];
      if (config[key] !== undefined) {
        nextValue[key] = config[key];
      }
    }

    nextValue.tid = config.tid;
    return nextValue;
  };

  _calcPrice = () => {
    let { itemId, appActions } = this.props;
    let { values } = this.state;

    this._showSpinner(1000);

    appActions.cart
      .getPrice({
        id: itemId,
        data: values,
      })
      .then(result => {
        this.setState({ price: result.price });
      });
  };

  _onInputUpdate = key => e => {
    let { values } = this.state;
    this.setState(
      {
        values: {
          ...values,
          [key]: e.target.value,
        },
        price: null,
        addToCartResult: null,
      },
      () => {
        let { onConfigInvalidated } = this.props;
        if (onConfigInvalidated) {
          onConfigInvalidated(true);
        }
      }
    );
  };

  _modifyCart = action => () => {
    let { itemId, appActions } = this.props;
    let { values } = this.state;

    this._showSpinner(1000);

    appActions.cart
      .configItem(action, {
        id: itemId,
        data: values,
      })
      .then(() => {
        if (action === 'add') {
          this.setState({
            addToCartResult: {
              success: true,
            },
          });
        }
      })
      .catch(error => {
        if (action === 'add') {
          this.setState({
            addToCartResult: {
              success: false,
              error: error,
            },
          });
        }
      });
  };

  _showSpinner = ms => {
    this.setState({ showSpinner: true });
    setTimeout(() => this.setState({ showSpinner: false }), ms);
  };

  static RowWrapper = styled.div`
    padding: 20px 0px 20 20px;
    ${props => props.extraCss || ''};
  `;

  static Wrapper = styled.div`
    padding: 20px;
    border-radius: 4px;
    box-shadow: 0 0 13px 0 #e0e0e0;
    ${props => props.extraCss || ''};
  `;

  static Field = styled.div`
    margin-bottom: 10px;

    & > label {
      margin-right: 10px;
    }

    & > input {
      border: none;
      border-bottom: 1px solid #ccc;
      text-align: right;
      width: 100px;
    }

    & > input:focus {
      outline: none;
    }

    & > select {
      width: 100px;
      text-align-last: right;
    }

    & > select > option {
      direction: rtl;
    }
  `;

  static PriceBar = styled.div`
    display: flex;
    justify-content: flex-end;
    padding-bottom: 10px;

    & > h3 {
      color: black;
      ${props => (props.lineThrough && 'text-decoration: line-through;') || ''};
    }
  `;

  static ActionButtonBar = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    border-top: 1px solid #ccc;
    padding-top: 10px;

    & > h3 {
      color: green;
    }

    & > button {
      display: inline-block;
      border: none;
      padding: 8px 12px;
      margin-left: 10px;
      text-decoration: none;
      background: #0069ed;
      color: #ffffff;
      font-family: sans-serif;
      font-size: 1rem;
      cursor: pointer;
      text-align: center;
      transition: background 250ms ease-in-out, transform 150ms ease;
      -webkit-appearance: none;
      -moz-appearance: none;
    }

    & > button:hover {
      font-weight: 900;
      background: #0053ba;
    }

    & > button:focus {
      background: #0053ba;
      outline: none;
    }

    & > button:active {
      transform: scale(0.95);
    }
  `;

  static Status = styled.div`
    margin-bottom: 10px;

    & > h3 {
      color: grey;
    }

    & > div {
      margin-top: 5px;
      font-size: 12px;
      color: grey;
    }
  `;
}

export default connect(
  (state, ownProps) => ({
    profile: Selectors.getLoginUser(state),
    product: Selectors.getProduct(state, ownProps.itemId),
  }),
  ActionCreator
)(ItemInCart);
