import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { withPage } from '../../Page';
import Selectors from '../../Selectors';
import ActionCreator from '../../ActionCreator';
import ItemInCart from '../../Components/ItemInCart';
import CheckoutForm from '../../Components/CheckoutForm';
import * as Widget from '../../Components/Widget';

const UiState = {
  CART: 'CART',
  CHECKOUT: 'CHECKOUT',
};

class CartPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      uiState: UiState.CART,
      showSpinner: false,
    };

    // special property to deal with item config change
    this.dirtyMap = {};
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.cartData !== this.props.cartData) {
      this.dirtyMap = {};
      this.forceUpdate();
    }
  }

  render() {
    let { uiState, showSpinner } = this.state;
    let { profile, cartData } = this.props;

    if (!profile) {
      return <CartPage.Wrapper>請先登入</CartPage.Wrapper>;
    }

    let isEmpty = Object.keys(cartData.items).length === 0;
    let isDirty = Object.keys(this.dirtyMap).length > 0;

    if (uiState === UiState.CART) {
      return (
        <CartPage.Wrapper>
          <CartPage.CartItems>
            <h2 style={{ textAlign: 'center' }}>購物車</h2>

            {this._renderAllItemsInCart()}

            {isEmpty ? (
              '您的購物車目前為空!'
            ) : (
              <div>
                <CartPage.PriceBar lineThrough={isDirty}>
                  <h2>{`總計 $${cartData.price}`}</h2>
                </CartPage.PriceBar>

                <CartPage.PriceBar
                  extraCss="margin-top: 0;"
                  lineThrough={isDirty}
                >
                  <div>{`* 含運費 $${cartData.shipping_fee}`}</div>
                </CartPage.PriceBar>
              </div>
            )}
          </CartPage.CartItems>

          {showSpinner && (
            <Widget.Center>
              <Widget.Spinner />
            </Widget.Center>
          )}

          {!isEmpty &&
            !showSpinner && (
              <CartPage.NextStep
                onClick={() => {
                  this._showSpinner(1000).then(() => {
                    this.setState({ uiState: UiState.CHECKOUT }, () => {
                      window.scrollTo(0, 0);
                    });
                  });
                }}
                disabled={isDirty}
              >
                下一步
              </CartPage.NextStep>
            )}
        </CartPage.Wrapper>
      );
    } else {
      return (
        <CartPage.Wrapper>
          <CartPage.NextStep
            onClick={() => this.setState({ uiState: UiState.CART })}
          >
            上一步
          </CartPage.NextStep>

          <CheckoutForm extraCss="margin-top: 10px;" />
        </CartPage.Wrapper>
      );
    }
  }

  _renderAllItemsInCart = () => {
    let { cartData } = this.props;
    let configs = [];
    for (let itemId in cartData.items) {
      let item = cartData.items[itemId];
      configs = [
        ...configs,
        ...item.configs.map(c => (
          <ItemInCart
            key={c.tid}
            itemId={item.id}
            config={c}
            onConfigInvalidated={dirty => {
              if (dirty) {
                this.dirtyMap[c.tid] = true;
              } else {
                delete this.dirtyMap[c.tid];
              }

              this.forceUpdate();
            }}
            display="cart"
          />
        )),
      ];
    }

    return <div>{configs}</div>;
  };

  _showSpinner = ms => {
    this.setState({ showSpinner: true });
    return new Promise(resolve => {
      setTimeout(() => {
        this.setState({ showSpinner: false });
        resolve();
      }, ms);
    });
  };

  static Wrapper = styled.div`
    max-width: 800px;
    padding: 20px;
    margin: 0 auto;
  `;

  static CartItems = styled.div`
    border-radius: 4px;
    padding: 20px;
    margin-bottom: 30px;
    box-shadow: 0 0 13px 0 #e0e0e0;
  `;

  static PriceBar = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
    ${props => props.extraCss || ''} & > h2 {
      ${props => (props.lineThrough ? 'text-decoration: line-through;' : '')};
    }

    & > div {
      color: grey;
      font-size: 12px;
      ${props => (props.lineThrough ? 'text-decoration: line-through;' : '')};
    }
  `;

  static NextStep = styled.button`
    display: block;
    margin: 0 auto;
    padding: 8px 12px;
    border-radius: 28px;
    font-size: 20px;
    min-width: 200px;
    ${props => (props.disabled ? 'color: #ccc;' : '')};
  `;
}

export default withPage(
  connect(
    (state, ownProps) => ({
      profile: Selectors.getLoginUser(state),
      cartData: Selectors.cart.getData(state),
    }),
    ActionCreator
  )(CartPage)
);
